
























































































































































































































































































































































































































































































































































































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import axios from 'axios';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import TypeaheadMixin from '@/mixins/TypeaheadMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import CreateAndUpdateFormMixin from '@/mixins/CreateAndUpdateFormMixin';
import FormatMixin from '@/mixins/FormatMixin';
import { RemoteData } from 'rey-frontend-fp';
import ISampleTaker from '@/models/ISampleTaker';
import IOrderDescriptor from '@/models/IOrderDescriptor';
import Language from '@/models/Language';
import ReportType from '@/models/ReportType';
import OrderType from '@/models/OrderType';
import ContractType from '@/models/ContractType';
import InvoiceMode from '@/models/InvoiceMode';
import IOrderExpanded from '@/models/IOrderExpanded';
import ICustomer from '@/models/ICustomer';
import IReport from '@/models/IReport';
import IGenerateReport from '@/models/IGenerateReport';
import CrmCustomerViewer from '@/components/CrmCustomerViewer.vue';
import CrmCustomerTypeahead from '@/components/CrmCustomerTypeahead.vue';
import SettingsMixin from '@/mixins/SettingsMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';

export default mixins(
  TypeaheadMixin,
  CreateAndUpdateFormMixin,
  OrderViewHelperMixin,
  SharedDataMixin,
  SettingsMixin,
  DateTimeMixin,
  FormatMixin
).extend({
  components: { CrmCustomerTypeahead, CrmCustomerViewer },
  data: function () {
    return {
      sampleTakersRd: RemoteData.notAsked<Array<ISampleTaker>, Error>(),
      orderDescriptorsRd: RemoteData.notAsked<Array<IOrderDescriptor>, Error>(),
      refreshingCrmCustomer: false,
      orderPreviewImage: '',
      orderPreviewImageIsGenerating: false,
      cancelTokenSource: axios.CancelToken.source(),
      gatedUpdateDebounced: _.debounce(
        function () {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const vm = this;
          let valid = true;
          const extraCharge1TextInput = vm.$refs.extraCharge1Text as HTMLInputElement;
          const extraCharge2TextInput = vm.$refs.extraCharge2Text as HTMLInputElement;
          const extraCharge3TextInput = vm.$refs.extraCharge3Text as HTMLInputElement;
          extraCharge1TextInput.setCustomValidity('');
          extraCharge2TextInput.setCustomValidity('');
          extraCharge3TextInput.setCustomValidity('');

          if (
            vm.model.extraCharge1InChf != 0 &&
            (vm.model.extraCharge1Text || '').trim().length == 0
          ) {
            valid = false;
            if (vm.$refs.extraCharge1Text) {
              extraCharge1TextInput.setCustomValidity('Bitte geben Sie einen Text ein.');
              extraCharge1TextInput.reportValidity();
            }
          }

          if (
            vm.model.extraCharge2InChf != 0 &&
            (vm.model.extraCharge2Text || '').trim().length == 0
          ) {
            valid = false;
            if (vm.$refs.extraCharge2Text) {
              extraCharge2TextInput.setCustomValidity('Bitte geben Sie einen Text ein.');
              extraCharge2TextInput.reportValidity();
            }
          }

          if (
            vm.model.extraCharge3InChf != 0 &&
            (vm.model.extraCharge3Text || '').trim().length == 0
          ) {
            valid = false;
            if (vm.$refs.extraCharge3Text) {
              extraCharge3TextInput.setCustomValidity('Bitte geben Sie einen Text ein.');
              extraCharge3TextInput.reportValidity();
            }
          }

          if (valid) {
            vm.updateDebounced();
          }
        }.bind(this),
        1000
      )
    };
  },
  created: async function () {
    await this.loadSampleTakers();
    await this.loadOrderDescriptors();
  },
  mounted: async function () {
    // @ts-ignore
    this.setFormRef(this.$refs.orderForm);
    await this.createOrderPreviewImage(this.model.id);
  },
  watch: {
    model: async function () {
      await this.createOrderPreviewImage(this.model.id);
    }
  },
  computed: {
    filteredOrderDescriptors: function (): Array<IOrderDescriptor> {
      return this.orderDescriptorsRd
        .withDefault([])
        .filter((od) => od.departmentCode === this.$department);
    },
    invoiceTotalChf: function (): number {
      const order = this.model as IOrderExpanded;
      if (
        order.invoiceMode === InvoiceMode.Standard &&
        order.contractType === ContractType.Standard
      ) {
        return (
          // @ts-ignore
          parseFloat(order.samplesInHouseTotalChf || 0) +
          // @ts-ignore
          parseFloat(order.samplesSubcontractedTotalChf || 0) -
          // @ts-ignore
          parseFloat(order.samplesInHouseDiscountChf || 0) +
          // @ts-ignore
          parseFloat(order.extraCharge1InChf || 0) +
          // @ts-ignore
          parseFloat(order.extraCharge2InChf || 0) +
          // @ts-ignore
          parseFloat(order.extraCharge3InChf || 0) +
          // @ts-ignore
          parseFloat(order.orderFlatRateInChf || 0)
        );
      } else {
        return 0;
      }
    },
    languages: function (): Array<{ id: number; name: string }> {
      return this.enumToObjectArray(Language);
    },
    reportTypes: function (): Array<{ id: number; name: string }> {
      return this.enumToObjectArray(ReportType).map((rt: { id: number; name: string }) => ({
        id: rt.id,
        name: rt.name === 'GoodManufacturingPractice' ? 'GMP' : rt.name
      }));
    },
    orderTypes: function (): Array<{ id: number; name: string; title: string }> {
      return this.enumToObjectArray(OrderType).map((ot: { id: number; name: string }) => ({
        id: ot.id,
        name: ot.name,
        title: ot.id === 1 ? 'Ohne Probe' : ot.name
      }));
    },
    contractTypes: function (): Array<{ id: number; name: string }> {
      return this.enumToObjectArray(ContractType);
    },
    invoiceModes: function (): Array<{ id: number; name: string; title: string }> {
      return this.enumToObjectArray(InvoiceMode).map((ot: { id: number; name: string }) => ({
        id: ot.id,
        name: ot.name,
        title: ot.id === 1 ? 'Intern' : ot.name
      }));
    }
  },
  methods: {
    refreshCrmCustomer: async function () {
      this.refreshingCrmCustomer = true;
      try {
        if (this.model.customerNumber) {
          const customer: ICustomer = await this.loadCrmCustomer(this.model.customerNumber);

          const customerSalutation = this.model.customerSalutation;
          const customerContact = this.model.customerContact;
          const customerContactFirstName = this.model.customerContactFirstName;
          const customerContactLastName = this.model.customerContactLastName;
          this.model.setCustomerDetails(customer);
          this.model.customerSalutation = customerSalutation;
          this.model.customerContact = customerContact;
          this.model.customerContactFirstName = customerContactFirstName;
          this.model.customerContactLastName = customerContactLastName;

          if (this.isInUpdateMode === true) {
            this.$emit('update', this.model);
          }
        }
      } finally {
        this.refreshingCrmCustomer = false;
      }
    },
    validateAndCreateOrder: function () {
      if (this.isInUpdateMode === true) {
        return;
      }
      if (this.validateForm(this.$refs.orderForm as HTMLFormElement) === false) {
        return;
      }

      if (this.model.deliveryDate < this.model.receiptDate) {
        // @ts-ignore
        this.$refs.deliveryDate.setCustomValidity('Liefertermin ist vor Auftragseingang!');
        // @ts-ignore
        this.$refs.deliveryDate.reportValidity();
        return;
      }

      if (this.model.customerNumber <= 0) {
        const typeahead = this.typeaheadInputById('customerTypeAheadInput');
        this.setTypeaheadInputInvalid(typeahead, 'Wählen Sie einen Kunden aus.');

        return;
      }

      this.$emit('create', this.model);
    },
    addOrderDescription: async function (selection: IOrderDescriptor) {
      if (!selection) {
        return;
      }

      let prefix = '';
      if (!this.model.orderDescription) {
        // initialize string if undefined, null or empty
        this.model.orderDescription = '';
      } else {
        // add line break
        this.model.orderDescription += '\n';
        prefix = '\n';
      }

      this.model.orderDescription += this.getTranslatedValue(selection, this.model.language);
      // @ts-ignore
      this.$refs.orderDescriptor.value +=
        prefix + this.getTranslatedValue(selection, this.model.language);

      if (this.isInUpdateMode === true) {
        this.$emit('update', this.model);
      } else {
        this.$forceUpdate();
      }
    },
    loadSampleTakers: async function () {
      this.sampleTakersRd = await this.$labordatApi.getPagedRd(`/api/sampletaker`);
    },
    loadOrderDescriptors: async function () {
      this.orderDescriptorsRd = await this.$labordatApi.getPagedRd(`/api/orderdescriptor`);
    },
    loadCrmCustomer: function (customerNumber: number) {
      return this.$labordatApi
        .get(`/api/crm/customers/${customerNumber}`)
        .then((response) => response.data as ICustomer);
    },
    async createOrderPreviewImage(orderId: string) {
      this.orderPreviewImageIsGenerating = true;
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();

      const reports = (await this.$labordatApi.get(`/api/orders/${orderId}/reports`, {
        cancelToken: this.cancelTokenSource.token
      })) as { data: Array<IReport> };

      const reportIdToUseForPreview = reports.data.find((r: IReport) => !r.variant)?.id;
      if (reportIdToUseForPreview === undefined) {
        this.orderPreviewImage = '';
        return;
      }

      await this.$labordatApi
        .post(
          `/api/orders/${orderId}/reports/orderpreviewimage`,
          {
            sampleIds: [0],
            reportId: reportIdToUseForPreview
          } as IGenerateReport,
          {
            responseType: 'arraybuffer',
            timeout: 3600000,
            cancelToken: this.cancelTokenSource.token
          }
        )
        .then((response) => {
          this.orderPreviewImageIsGenerating = false;
          if (response != null) {
            this.orderPreviewImage =
              'data:image/jpg;base64,' + Buffer.from(response.data).toString('base64');
          } else {
            this.orderPreviewImage = '';
            throw new Error('No document generated.');
          }
        });
    }
  }
});
